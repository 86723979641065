import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

function AgendaHeader(props) {


    return (
        <nav className="navbar navbar-expand-lg fixed-top mwc-navbar">
            
            <div className="head_container">
         
                <div className='logo'>
                    <a className="navbar-brand animated animatedFadeInUp fadeInUp" href="/">
                        <img src={'/assets/img/midwestcon-logo-new.png'} alt="" className="mw-100" />
                    </a>
                    <div className='sub_logo'>
                        <span>POWERED by</span>
                        <a className="navbar-brand animated animatedFadeInUp fadeInUp" href="">
                            <img src={'/assets/img/disruption-now.jpg'} alt="" className="mw-100" />
                        </a>
                        <a className="navbar-brand animated animatedFadeInUp fadeInUp" href="">
                            <img src={'/assets/img/for_our_logo.jpg'} alt="" className="mw-100" />
                        </a>
                    </div>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                
                    <ul className="navbar-nav mb-lg-0 ms-auto">
                    
                        <li className="nav-item animated2 animatedFadeInUp fadeInUp">
                            <NavLink to="/">Back to home</NavLink>
                        </li>
                        
                        
                    </ul>
                </div>
            </div>
        </nav>

    );
}

export { AgendaHeader };
