import React, { useEffect } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { AgendaHeader } from "./AgendaHeader";

const MainAgenda = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");

    // Set the script attributes
    script.src =
      "https://whova.com/static/frontend/xems/js/embed/embedagenda.js?eid=w68UDfEK56wZpeR-ok5HXgYjsMd-E986-Cf1snGI1Ks%3D&host=https://whova.com";
    script.type = "text/javascript";
    script.id = "embeded-agenda-script";

    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="App">
      <AgendaHeader  />
      <div className="mwc-event-schedule">
    <div className="container">
      <div title="Whova event and conference app" id="whova-agendawidget">
        <p id="whova-loading">Loading...</p>
      </div>
      {/* <div id="whova-wrap" className="whova-wrap-class">
        Powered By
        <a
          className="brandlink"
          target="_blank"
          href="https://whova.com"
          rel="noopener noreferrer"
        >
          <b>Whova</b>
        </a>
        <br />
        <a
          className="whova-emslink brandanchorlink"
          target="_blank"
          href="https://whova.com/blog/free-event-planning-software-make-you-rockstar/"
          rel="noopener noreferrer"
        >
          Event management platform
        </a>
      </div> */}
    </div>
    </div>
    <Footer />
    </div>
  );
};

export default MainAgenda;
